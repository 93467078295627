<template>
  <div class="foreign-page">
    <template v-if="imgUrl.length > 0">
      <div
        class="img-box"
        v-for="(item, index) in imgUrl?.[imgIndex]?.['picUrls'] || []"
        :key="index"
      >
        <img :src="item" alt="" />
      </div>
    </template>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { getEnglishData } from "@/api/index.js";
import { useEnglishDataStore } from "@/store/englishData";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { trackEvent } from "@/utils/tracklog.js";

const route = useRoute();

const store = useEnglishDataStore();
const { englishData } = storeToRefs(store);
const imgUrl = ref([]);
const imgIndex = ref(0);

onMounted(() => {
  //埋点 英文官网
  trackEvent(
    `英文官网`,
    "浏览",
    `英文官网`,
    {},
    0,
    new Date().getTime()
  );
  getEnglishData().then((res) => {
    console.log("res", res);
    imgUrl.value = res;
    if (route.query?.id) {
      let index = imgUrl.value.findIndex((item) => item.id == route.query?.id);
      imgIndex.value = index;
    }
    store.setEnglishData(res);
    console.log("englishData.value", englishData.value);
  });
});
</script>

<style lang="scss" scoped>
.img-box {
  width: 100%;
  img {
    width: 100%;
  }
}

.foreign-page {
  min-height: 50vh;
}
</style>